<template>
  <div class="nb-card-wrapper" :id="content.id || ''">
    <div
      v-for="card in content.cards"
      class="nb-card"
      :class="{
        'nb-yellow': card.fields?.color?.toLowerCase() === 'yellow',
        'nb-blue': card.fields?.color?.toLowerCase() === 'blue',
        'nb-pink': card.fields?.color?.toLowerCase() === 'pink',
        'nb-purple': card.fields?.color?.toLowerCase() === 'purple',
        'nb-white': card.fields?.color?.toLowerCase() === 'white',
        'nb-green': card.fields?.color?.toLowerCase() === 'green',
        'nb-red': card.fields?.color?.toLowerCase() === 'red',
        'nb-gold': card.fields?.color?.toLowerCase() === 'gold',
        'nb-rose': card.fields?.color?.toLowerCase() === 'rose',
        'nb-social-media': card.fields?.socialMediaUrl,
      }"
      :key="card.fields?.title"
      @click="clickHandler(card.fields?.socialMediaUrl)"
    >
      <div
        class="nb-card-role"
        :class="{
          'nb-card-role-small': card.fields?.role?.length > 7,
          'nb-card-role-xsmall': card.fields?.role?.length > 10,
        }"
      >
        {{ card.fields?.role }}
      </div>

      <div
        v-if="card.fields?.image"
        class="nb-card-content"
        :class="{
          'nb-card-content--image-only': !card.fields?.socialMediaText,
        }"
      >
        <img :src="card.fields?.image.fields?.file?.url" />
        <div
          v-if="card.fields?.photographer && card.fields?.photographer"
          class="nb-card-url"
        >
          📷 {{ card.fields.photographer }}
        </div>
      </div>

      <div v-else-if="card.fields?.content" class="nb-card-content">
        <RichTextRenderer :document="card.fields?.content" />
      </div>

      <div
        v-if="card.fields?.rules?.content"
        class="nb-card-content nb-card-content-info"
      >
        <div class="nb-card-section">
          <RichTextRenderer :document="card.fields?.rules" />
        </div>
        <div class="nb-card-section">
          <div class="nb-card-section-main">{{ card.fields?.sectionName }}</div>
          <div class="nb-card-section-sub">
            {{ card.fields?.subSectionName }}
          </div>
        </div>
      </div>

      <a
        v-if="card.fields?.socialMediaText && card.fields?.socialMediaUrl"
        class="nb-card-url"
        :href="card.fields?.socialMediaUrl"
      >
        {{ card.fields?.socialMediaText }}
      </a>
      <div v-else-if="card.fields?.socialMediaText" class="nb-card-url">
        {{ card.fields?.socialMediaText }}
      </div>
    </div>
  </div>
</template>

<script>
import RichTextRenderer from "contentful-rich-text-vue-renderer";

export default {
  name: "NeubrutalismCardWrapper",
  props: {
    content: Object,
  },
  components: {
    RichTextRenderer,
  },
  data() {
    return {};
  },
  methods: {
    clickHandler(url) {
      if (url?.length) {
        window.open(url, "_blank");
      }
    },
  },
};
</script>

<style lang="scss">
.nb-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px 0 0;

  @media (min-width: $sm) {
    padding: 32px;
  }

  @media (min-width: $md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 48px 0 -32px;
    padding: 0;
  }

  @media (min-width: $xl) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 12px;
  }

  .nb-card {
    aspect-ratio: 1/1;
    position: relative;
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    margin-bottom: 16px;

    &.nb-social-media {
      cursor: pointer;

      .nb-card-content {
        .nb-card-url {
          font-size: 12px;
          padding: 2px 4px;
          background: #ffffffcc;
          border: 8px;
        }
      }

      &:hover {
        .nb-card-content {
          top: 10px;
          left: 10px;
          &:before {
            right: 0;
            bottom: 0;
          }

          .nb-card-url {
            bottom: 48px;
            left: 38px;
          }
        }
        .nb-card-url {
          bottom: 38px;
          left: 48px;
        }
        .nb-card-role {
          top: -14px;
          left: 24px;
        }
      }
    }

    // &.nb-yellow {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-yellow;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-yellow;

    //     &:before {
    //       background: $sewcase-nb-yellow;
    //     }
    //   }
    // }

    // &.nb-blue {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-blue;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-black;

    //     &:before {
    //       background: $sewcase-nb-blue;
    //     }
    //   }
    // }

    // &.nb-pink {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-pink;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-pink;

    //     &:before {
    //       background: $sewcase-nb-pink;
    //     }
    //   }
    // }

    // &.nb-purple {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-purple;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-purple;

    //     &:before {
    //       background: $sewcase-nb-purple;
    //     }
    //   }
    //   .nb-card-url {
    //     color: $sewcase-white;
    //   }
    // }

    // &.nb-white {
    //   .nb-card-content {
    //     background-color: $sewcase-white;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-black;

    //     &:before {
    //       background: $sewcase-white;
    //     }
    //   }
    //   .nb-card-url {
    //     color: $sewcase-black;
    //   }
    // }

    // &.nb-green {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-green;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-green;

    //     &:before {
    //       background: $sewcase-nb-green;
    //     }
    //   }
    //   .nb-card-url {
    //     color: $sewcase-black;
    //   }
    // }

    // &.nb-red {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-red;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-red;

    //     &:before {
    //       background: $sewcase-nb-red;
    //     }
    //   }
    //   .nb-card-url {
    //     color: $sewcase-black;
    //   }
    // }

    // &.nb-gold {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-gold;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-gold;

    //     &:before {
    //       background: $sewcase-nb-gold;
    //     }
    //   }
    //   .nb-card-url {
    //     color: $sewcase-black;
    //   }
    // }

    // &.nb-rose {
    //   .nb-card-content {
    //     background-color: $sewcase-nb-rose;
    //   }
    //   .nb-card-role {
    //     text-shadow: 4px 3px $sewcase-nb-rose;

    //     &:before {
    //       background: $sewcase-nb-rose;
    //     }
    //   }
    //   .nb-card-url {
    //     color: $sewcase-black;
    //   }
    // }
  }

  .nb-card-content {
    border: 3px solid $sewcase-black;
    border-radius: $border-radius-sm;
    background: $sewcase-nb-blue;
    padding: 24px 36px 60px;
    height: calc(100% - 32px);
    width: calc(100% - 32px);
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.2s ease;

    h4 {
      margin: 8px 0;
    }

    &:before {
      content: "";
      position: absolute;
      background: $sewcase-black;
      border-radius: $border-radius-sm;
      right: -20px;
      bottom: -20px;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
      z-index: -1;
    }

    &.nb-card-content-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 12px;
    }

    &.nb-card-content--image-only {
      padding: 24px;

      img {
        border-radius: 8px;
        border: none;
      }
    }

    img {
      border-radius: 8px 8px 0 0;
      border-bottom: 3px solid $sewcase-black;
      width: 100%;
    }
  }

  .nb-card-role {
    position: absolute;
    top: -36px;
    left: 16px;
    color: $sewcase-nb-gold;
    text-shadow: 4px 3px $sewcase-black;
    font-size: 56px;
    font-weight: 800;
    transition: all 0.2s ease;
    z-index: 1;

    @media (min-width: $md) {
      font-size: 48px;
    }
    @media (min-width: $lg) {
      font-size: 56px;
    }

    &:before {
      content: "";
      width: 100%;
      position: absolute;
      height: 3px;
      top: 36px;
      left: 1px;
      z-index: -1;
    }

    &.nb-card-role-small {
      font-size: 42px;
      top: -24px;

      @media (min-width: $md) {
        font-size: 36px;
      }
      @media (min-width: $lg) {
        font-size: 42px;
      }

      &:before {
        top: 24px;
      }
    }

    &.nb-card-role-xsmall {
      font-size: 34px;
      top: -24px;

      @media (min-width: $md) {
        font-size: 38px;
      }
      @media (min-width: $lg) {
        font-size: 34px;
      }

      &:before {
        top: 24px;
      }
    }
  }

  .nb-card-url {
    position: absolute;
    bottom: 48px;
    left: 38px;
    font-weight: 600;
    transition: all 0.2s ease;
  }

  .nb-card-section:last-child {
    border-top: 3px solid $sewcase-black;
    padding-top: 8px;
  }

  .nb-card-section {
    p {
      font-size: 14px;
      margin: 0;
    }

    h4 {
      margin-bottom: 0;
    }
  }

  .nb-card-section-main {
    font-size: 16px;
    font-weight: bold;
  }

  .nb-card-section-sub {
    font-size: 14px;
  }
}
</style>
