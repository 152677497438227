<template>
  <ComponentLoader class="contest-page" :components="getSpecificPage" />
</template>

<script>
import { mapGetters } from "vuex";
import ComponentLoader from "../components/ComponentLoader.vue";

export default {
  name: "contest-page",
  components: {
    ComponentLoader,
  },
  computed: {
    ...mapGetters(["getPagesEN", "getPagesDE", "getLanguage"]),

    getSpecificPage() {
      const page =
        this.getLanguage == "de-DE" ? this.getPagesDE : this.getPagesEN;

      const specificPage = page.find((page) => {
        const pageSlug =
          page.fields.slug !== "/" ? "/" + page.fields.slug : "/";
        return pageSlug == this.$route.path;
      });

      return specificPage?.fields?.reference;
    },
  },
  mounted() {
    this.setupStyles();
  },
  methods: {
    setupStyles() {
      this.styleElement = document.createElement("style");
      this.styleElement.textContent = `
        body { 
          background-color: #083742; 
        }
        nav {
          background-color: #083742;
        }
        nav a {
          color: #fafafa;
        }
        .nav-lang-switch-item {
          color: #fafafa;
        }
      `;
      this.styleElement.id = "contest-style";

      document.head.appendChild(this.styleElement);
    },
  },
  beforeUnmount() {
    const styleElement = document.getElementById("contest-style");
    if (styleElement && styleElement.parentNode) {
      styleElement.parentNode.removeChild(styleElement);
    }
  },
};
</script>

<style lang="scss">
.contest-page {
  padding-bottom: $spacing-lg;
  hr {
    border-color: #000000;
    border-width: 2px;
    margin: 32px 0;
  }
  p {
    line-height: 1.3;
  }

  .nb-card-content,
  .nb-card-wrapper .nb-card-content,
  .richtext-wrapper {
    padding-bottom: 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    strong {
      color: $sewcase-white;
    }

    .nb-card-url {
      color: $sewcase-black;
    }
  }

  .text-image-container-nb {
    .text-image-content {
      background-color: $sewcase-nb-blue;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      strong {
        color: $sewcase-white;
      }

      a:hover {
        color: $sewcase-nb-green;
      }
    }
  }

  .hero-image-sidebar-sub-headline--neubrutalism {
    text-shadow: 6px 5px $sewcase-nb-purple;
  }

  .nav-sub-item-container .nav-item {
    color: $sewcase-black;
  }

  .nb-card-wrapper .nb-card-url {
    color: $sewcase-white;
  }

  .accordion__container {
    border-radius: 8px;
    border-width: 3px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background: $sewcase-black;
      border-radius: $border-radius-sm;
      right: -20px;
      bottom: -20px;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
      z-index: -1;
    }

    .accordion__headline {
      background: $sewcase-nb-purple;
      border-radius: 4px 4px 0 0;
      border-bottom: 3px solid $sewcase-black;
      color: $sewcase-white;

      .accordion__toggle {
        &:before,
        &:after {
          background: $sewcase-white;
        }
      }
    }
    .accordion__content {
      background: $sewcase-nb-purple;
      border-radius: 0 0 4px 4px;
      * {
        color: $sewcase-white;
      }
      a {
        color: $sewcase-secondary;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
